@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300italic,400,400italic,600");
@import url("assets/css/fontawesome-all.min.css");
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&display=swap');

/*
	Helios by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
	font-size: 22px;
	font-weight: 300;
}

blockquote, q {
	quotes: none;
}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	-webkit-text-size-adjust: none;
}

mark {
	background-color: transparent;
	color: inherit;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input, select, textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
}
.font-2 {
	font-size: 2em;
	border-bottom: none!important;
}


/* Basic */

	html {
		box-sizing: border-box;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	body {
		background: #f0f4f4!important;
		color: #5b5b5b;
		font-size: 18px;
		line-height: 1.85em;
		font-family: 'Raleway', sans-serif!important;
		font-weight: 300;
	}

		body.is-preload *, body.is-preload *:before, body.is-preload *:after {
			-moz-animation: none !important;
			-webkit-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
			-moz-transition: none !important;
			-webkit-transition: none !important;
			-ms-transition: none !important;
			transition: none !important;
		}

	input, textarea, select {
		font-size: 15pt;
		line-height: 1.85em;
		font-family: 'Raleway', sans-serif;
		font-weight: 300;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 400;
		color: #483949;
		line-height: 1.25em;
	}

	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
		color: inherit;
		text-decoration: none;
		border-bottom-color: transparent;
	}

	h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
		font-weight: 600;
	}

	h2 {
		font-size: 2.15em;
	}

	h3 {
		font-size: 1.25em;
	}

	h4 {
		font-size: 1em;
		margin: 0 0 0.25em 0;
	}

	strong, b {
		font-weight: 400;
		color: #483949;
	}

	em, i {
		font-style: italic;
	}

	a {
		color: #2f6a9c!important;
		border-bottom: solid 1px rgba(128, 128, 128, 0.15);
		text-decoration: none;
		-moz-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
		-webkit-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
		-ms-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
		transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
	}
	a:hover {
			color: #adb2cf!important;
			border-bottom-color: transparent;
	}

	sub {
		position: relative;
		top: 0.5em;
		font-size: 0.8em;
	}

	sup {
		position: relative;
		top: -0.5em;
		font-size: 0.8em;
	}

	blockquote {
		border-left: solid 0.5em #ddd;
		padding: 1em 0 1em 2em;
		font-style: italic;
	}

	p, ul, ol, dl, table {
		margin-bottom: 1em;
	}

	p {
		text-align: justify;
	}
	.text-white {
		color: #fff;
	}
	.w250 {
		width: 250px;
	}
  article p {
    font-size: 16pt;
    line-height: 1.5;
    font-weight: 300;
  }
  .fixed-bg {
	background-image: url('assets/images/work3.jpg');
	height: 400px;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	}
  .row {
    --bs-gutter-x: 3rem!important;
  }
	br.clear {
		clear: both;
	}

	hr {
		position: relative;
		display: block;
		border: 0;
		top: 4.5em;
		margin-bottom: 9em;
		height: 6px;
		border-top: solid 1px rgba(128, 128, 128, 0.2);
		border-bottom: solid 1px rgba(128, 128, 128, 0.2);
	}

		hr:before, hr:after {
			content: '';
			position: absolute;
			top: -8px;
			display: block;
			width: 1px;
			height: 21px;
			background: rgba(128, 128, 128, 0.2);
		}

		hr:before {
			left: -1px;
		}

		hr:after {
			right: -1px;
		}

	.timestamp {
		color: rgba(128, 128, 128, 0.75);
		font-size: 0.8em;
	}
	.w100 {
		width: 100%
	}
	.personnel-lists {
		margin: 20px 0 0 60px;
	}
	
/* Container */

	.container {
		margin: 0 auto;
		max-width: 100%;
		width: 1400px;
	}

		@media screen and (max-width: 1680px) {

			.container {
				width: 1200px;
			}

		}

		@media screen and (max-width: 1280px) {

			.container {
				width: 100%;
			}

		}

		@media screen and (max-width: 960px) {

			.container {
				width: 100%;
			}

		}

		@media screen and (max-width: 840px) {

			.container {
				width: 100%;
			}

		}

		@media screen and (max-width: 736px) {

			.container {
				width: 100%;
			}

		}

/* Row */

	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
	}

		.row > * {
			box-sizing: border-box;
		}

		.row.gtr-uniform > * > :last-child {
			margin-bottom: 0;
		}

		.row.aln-left {
			justify-content: flex-start;
		}

		.row.aln-center {
			justify-content: center;
		}

		.row.aln-right {
			justify-content: flex-end;
		}

		.row.aln-top {
			align-items: flex-start;
		}

		.row.aln-middle {
			align-items: center;
		}

		.row.aln-bottom {
			align-items: flex-end;
		}

		.row > .imp {
			order: -1;
		}

		.row > .col-1 {
			width: 8.33333%;
		}

		.row > .off-1 {
			margin-left: 8.33333%;
		}

		.row > .col-2 {
			width: 16.66667%;
		}

		.row > .off-2 {
			margin-left: 16.66667%;
		}

		.row > .col-3 {
			width: 25%;
		}

		.row > .off-3 {
			margin-left: 25%;
		}

		.row > .col-4 {
			width: 33.33333%;
		}

		.row > .off-4 {
			margin-left: 33.33333%;
		}

		.row > .col-5 {
			width: 41.66667%;
		}

		.row > .off-5 {
			margin-left: 41.66667%;
		}

		.row > .col-6 {
			width: 50%;
		}

		.row > .off-6 {
			margin-left: 50%;
		}

		.row > .col-7 {
			width: 58.33333%;
		}

		.row > .off-7 {
			margin-left: 58.33333%;
		}

		.row > .col-8 {
			width: 66.66667%;
		}

		.row > .off-8 {
			margin-left: 66.66667%;
		}

		.row > .col-9 {
			width: 75%;
		}

		.row > .off-9 {
			margin-left: 75%;
		}

		.row > .col-10 {
			width: 83.33333%;
		}

		.row > .off-10 {
			margin-left: 83.33333%;
		}

		.row > .col-11 {
			width: 91.66667%;
		}

		.row > .off-11 {
			margin-left: 91.66667%;
		}

		.row > .col-12 {
			width: 100%;
		}

		.row > .off-12 {
			margin-left: 100%;
		}

		.row.gtr-0 {
			margin-top: 0px;
			margin-left: 0px;
		}

			.row.gtr-0 > * {
				padding: 0px 0 0 0px;
			}

			.row.gtr-0.gtr-uniform {
				margin-top: 0px;
			}

				.row.gtr-0.gtr-uniform > * {
					padding-top: 0px;
				}

		.row.gtr-25 {
			margin-top: -10px;
			margin-left: -10px;
		}

			.row.gtr-25 > * {
				padding: 10px 0 0 10px;
			}

			.row.gtr-25.gtr-uniform {
				margin-top: -10px;
			}

				.row.gtr-25.gtr-uniform > * {
					padding-top: 10px;
				}

		.row.gtr-50 {
			margin-top: -20px;
			margin-left: -20px;
		}

			.row.gtr-50 > * {
				padding: 20px 0 0 20px;
			}

			.row.gtr-50.gtr-uniform {
				margin-top: -20px;
			}

				.row.gtr-50.gtr-uniform > * {
					padding-top: 20px;
				}

		.row {
			margin-top: -40px;
			margin-left: -40px;
		}

			.row > * {
				padding: 40px 0 0 40px;
			}

			.row.gtr-uniform {
				margin-top: -40px;
			}

				.row.gtr-uniform > * {
					padding-top: 40px;
				}

		.row.gtr-150 {
			margin-top: -60px;
			margin-left: -60px;
		}

			.row.gtr-150 > * {
				padding: 60px 0 0 60px;
			}

			.row.gtr-150.gtr-uniform {
				margin-top: -60px;
			}

				.row.gtr-150.gtr-uniform > * {
					padding-top: 60px;
				}

		.row.gtr-200 {
			margin-top: -80px;
			margin-left: -80px;
		}

			.row.gtr-200 > * {
				padding: 80px 0 0 80px;
			}

			.row.gtr-200.gtr-uniform {
				margin-top: -80px;
			}

				.row.gtr-200.gtr-uniform > * {
					padding-top: 80px;
				}

		@media screen and (max-width: 1680px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-wide {
					order: -1;
				}

				.row > .col-1-wide {
					width: 8.33333%;
				}

				.row > .off-1-wide {
					margin-left: 8.33333%;
				}

				.row > .col-2-wide {
					width: 16.66667%;
				}

				.row > .off-2-wide {
					margin-left: 16.66667%;
				}

				.row > .col-3-wide {
					width: 25%;
				}

				.row > .off-3-wide {
					margin-left: 25%;
				}

				.row > .col-4-wide {
					width: 33.33333%;
				}

				.row > .off-4-wide {
					margin-left: 33.33333%;
				}

				.row > .col-5-wide {
					width: 41.66667%;
				}

				.row > .off-5-wide {
					margin-left: 41.66667%;
				}

				.row > .col-6-wide {
					width: 50%;
				}

				.row > .off-6-wide {
					margin-left: 50%;
				}

				.row > .col-7-wide {
					width: 58.33333%;
				}

				.row > .off-7-wide {
					margin-left: 58.33333%;
				}

				.row > .col-8-wide {
					width: 66.66667%;
				}

				.row > .off-8-wide {
					margin-left: 66.66667%;
				}

				.row > .col-9-wide {
					width: 75%;
				}

				.row > .off-9-wide {
					margin-left: 75%;
				}

				.row > .col-10-wide {
					width: 83.33333%;
				}

				.row > .off-10-wide {
					margin-left: 83.33333%;
				}

				.row > .col-11-wide {
					width: 91.66667%;
				}

				.row > .off-11-wide {
					margin-left: 91.66667%;
				}

				.row > .col-12-wide {
					width: 100%;
				}

				.row > .off-12-wide {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -10px;
					margin-left: -10px;
				}

					.row.gtr-25 > * {
						padding: 10px 0 0 10px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -10px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 10px;
						}

				.row.gtr-50 {
					margin-top: -20px;
					margin-left: -20px;
				}

					.row.gtr-50 > * {
						padding: 20px 0 0 20px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -20px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 20px;
						}

				.row {
					margin-top: -40px;
					margin-left: -40px;
				}

					.row > * {
						padding: 40px 0 0 40px;
					}

					.row.gtr-uniform {
						margin-top: -40px;
					}

						.row.gtr-uniform > * {
							padding-top: 40px;
						}

				.row.gtr-150 {
					margin-top: -60px;
					margin-left: -60px;
				}

					.row.gtr-150 > * {
						padding: 60px 0 0 60px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -60px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 60px;
						}

				.row.gtr-200 {
					margin-top: -80px;
					margin-left: -80px;
				}

					.row.gtr-200 > * {
						padding: 80px 0 0 80px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -80px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 80px;
						}

		}

		@media screen and (max-width: 1280px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-normal {
					order: -1;
				}

				.row > .col-1-normal {
					width: 8.33333%;
				}

				.row > .off-1-normal {
					margin-left: 8.33333%;
				}

				.row > .col-2-normal {
					width: 16.66667%;
				}

				.row > .off-2-normal {
					margin-left: 16.66667%;
				}

				.row > .col-3-normal {
					width: 25%;
				}

				.row > .off-3-normal {
					margin-left: 25%;
				}

				.row > .col-4-normal {
					width: 33.33333%;
				}

				.row > .off-4-normal {
					margin-left: 33.33333%;
				}

				.row > .col-5-normal {
					width: 41.66667%;
				}

				.row > .off-5-normal {
					margin-left: 41.66667%;
				}

				.row > .col-6-normal {
					width: 50%;
				}

				.row > .off-6-normal {
					margin-left: 50%;
				}

				.row > .col-7-normal {
					width: 58.33333%;
				}

				.row > .off-7-normal {
					margin-left: 58.33333%;
				}

				.row > .col-8-normal {
					width: 66.66667%;
				}

				.row > .off-8-normal {
					margin-left: 66.66667%;
				}

				.row > .col-9-normal {
					width: 75%;
				}

				.row > .off-9-normal {
					margin-left: 75%;
				}

				.row > .col-10-normal {
					width: 83.33333%;
				}

				.row > .off-10-normal {
					margin-left: 83.33333%;
				}

				.row > .col-11-normal {
					width: 91.66667%;
				}

				.row > .off-11-normal {
					margin-left: 91.66667%;
				}

				.row > .col-12-normal {
					width: 100%;
				}

				.row > .off-12-normal {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -9px;
					margin-left: -9px;
				}

					.row.gtr-25 > * {
						padding: 9px 0 0 9px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -9px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 9px;
						}

				.row.gtr-50 {
					margin-top: -18px;
					margin-left: -18px;
				}

					.row.gtr-50 > * {
						padding: 18px 0 0 18px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -18px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 18px;
						}

				.row {
					margin-top: -36px;
					margin-left: -36px;
				}

					.row > * {
						padding: 36px 0 0 36px;
					}

					.row.gtr-uniform {
						margin-top: -36px;
					}

						.row.gtr-uniform > * {
							padding-top: 36px;
						}

				.row.gtr-150 {
					margin-top: -54px;
					margin-left: -54px;
				}

					.row.gtr-150 > * {
						padding: 54px 0 0 54px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -54px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 54px;
						}

					.row.gtr-200 {
						margin-top: -72px;
						margin-left: -72px;
					}

					.row.gtr-200 > * {
						padding: 72px 0 0 72px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -72px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 72px;
					}
					.carousel article {
						padding-bottom: 160px!important;
					}
					.carousel article p {
						height: 150px!important;
						align-items: center!important;
					}
					.carousel article p span {
						font-size: 16px!important;
					}

		}

		@media screen and (max-width: 960px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-narrow {
					order: -1;
				}

				.row > .col-1-narrow {
					width: 8.33333%;
				}

				.row > .off-1-narrow {
					margin-left: 8.33333%;
				}

				.row > .col-2-narrow {
					width: 16.66667%;
				}

				.row > .off-2-narrow {
					margin-left: 16.66667%;
				}

				.row > .col-3-narrow {
					width: 25%;
				}

				.row > .off-3-narrow {
					margin-left: 25%;
				}

				.row > .col-4-narrow {
					width: 33.33333%;
				}

				.row > .off-4-narrow {
					margin-left: 33.33333%;
				}

				.row > .col-5-narrow {
					width: 41.66667%;
				}

				.row > .off-5-narrow {
					margin-left: 41.66667%;
				}

				.row > .col-6-narrow {
					width: 50%;
				}

				.row > .off-6-narrow {
					margin-left: 50%;
				}

				.row > .col-7-narrow {
					width: 58.33333%;
				}

				.row > .off-7-narrow {
					margin-left: 58.33333%;
				}

				.row > .col-8-narrow {
					width: 66.66667%;
				}

				.row > .off-8-narrow {
					margin-left: 66.66667%;
				}

				.row > .col-9-narrow {
					width: 75%;
				}

				.row > .off-9-narrow {
					margin-left: 75%;
				}

				.row > .col-10-narrow {
					width: 83.33333%;
				}

				.row > .off-10-narrow {
					margin-left: 83.33333%;
				}

				.row > .col-11-narrow {
					width: 91.66667%;
				}

				.row > .off-11-narrow {
					margin-left: 91.66667%;
				}

				.row > .col-12-narrow {
					width: 100%;
				}

				.row > .off-12-narrow {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -8px;
					margin-left: -8px;
				}

					.row.gtr-25 > * {
						padding: 8px 0 0 8px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -8px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 8px;
						}

				.row.gtr-50 {
					margin-top: -16px;
					margin-left: -16px;
				}

					.row.gtr-50 > * {
						padding: 16px 0 0 16px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -16px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 16px;
						}

				.row {
					margin-top: -32px;
					margin-left: -32px;
				}

					.row > * {
						padding: 32px 0 0 32px;
					}

					.row.gtr-uniform {
						margin-top: -32px;
					}

						.row.gtr-uniform > * {
							padding-top: 32px;
						}

				.row.gtr-150 {
					margin-top: -48px;
					margin-left: -48px;
				}

					.row.gtr-150 > * {
						padding: 48px 0 0 48px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -48px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 48px;
						}

				.row.gtr-200 {
					margin-top: -64px;
					margin-left: -64px;
				}

					.row.gtr-200 > * {
						padding: 64px 0 0 64px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -64px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 64px;
						}

		}

		@media screen and (max-width: 840px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}
        .row {
          --bs-gutter-x: 0rem!important;
        }
        .mt6 {
          margin-top: 1em!important;
        }
        .services-icon {
          padding: 0!important;
          font-size: 64px!important;
        }
		.row > .col-12-sm {
			width: 100%;
		}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-narrower {
					order: -1;
				}

				.row > .col-1-narrower {
					width: 8.33333%;
				}

				.row > .off-1-narrower {
					margin-left: 8.33333%;
				}

				.row > .col-2-narrower {
					width: 16.66667%;
				}

				.row > .off-2-narrower {
					margin-left: 16.66667%;
				}

				.row > .col-3-narrower {
					width: 25%;
				}

				.row > .off-3-narrower {
					margin-left: 25%;
				}

				.row > .col-4-narrower {
					width: 33.33333%;
				}

				.row > .off-4-narrower {
					margin-left: 33.33333%;
				}

				.row > .col-5-narrower {
					width: 41.66667%;
				}

				.row > .off-5-narrower {
					margin-left: 41.66667%;
				}

				.row > .col-6-narrower {
					width: 50%;
				}

				.row > .off-6-narrower {
					margin-left: 50%;
				}

				.row > .col-7-narrower {
					width: 58.33333%;
				}

				.row > .off-7-narrower {
					margin-left: 58.33333%;
				}

				.row > .col-8-narrower {
					width: 66.66667%;
				}

				.row > .off-8-narrower {
					margin-left: 66.66667%;
				}

				.row > .col-9-narrower {
					width: 75%;
				}

				.row > .off-9-narrower {
					margin-left: 75%;
				}

				.row > .col-10-narrower {
					width: 83.33333%;
				}

				.row > .off-10-narrower {
					margin-left: 83.33333%;
				}

				.row > .col-11-narrower {
					width: 91.66667%;
				}

				.row > .off-11-narrower {
					margin-left: 91.66667%;
				}

				.row > .col-12-narrower {
					width: 100%;
				}

				.row > .off-12-narrower {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -8px;
					margin-left: -8px;
				}

					.row.gtr-25 > * {
						padding: 8px 0 0 8px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -8px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 8px;
						}

				.row.gtr-50 {
					margin-top: -16px;
					margin-left: -16px;
				}

					.row.gtr-50 > * {
						padding: 16px 0 0 16px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -16px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 16px;
						}

				.row {
					margin-top: -32px;
					margin-left: -32px;
				}

					.row > * {
						padding: 32px 0 0 32px;
					}

					.row.gtr-uniform {
						margin-top: -32px;
					}

						.row.gtr-uniform > * {
							padding-top: 32px;
						}

				.row.gtr-150 {
					margin-top: -48px;
					margin-left: -48px;
				}

					.row.gtr-150 > * {
						padding: 48px 0 0 48px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -48px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 48px;
						}

				.row.gtr-200 {
					margin-top: -64px;
					margin-left: -64px;
				}

					.row.gtr-200 > * {
						padding: 64px 0 0 64px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -64px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 64px;
						}

		}

		@media screen and (max-width: 736px) {
			article p {
				font-size: 14pt;
				line-height: 1.5;
				font-weight: 300;
			  }
			.personnel-lists {
				margin: 20px 0 0 0px!important;
			}

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-mobile {
					order: -1;
				}

				.row > .col-1-mobile {
					width: 8.33333%;
				}

				.row > .off-1-mobile {
					margin-left: 8.33333%;
				}

				.row > .col-2-mobile {
					width: 16.66667%;
				}

				.row > .off-2-mobile {
					margin-left: 16.66667%;
				}

				.row > .col-3-mobile {
					width: 25%;
				}

				.row > .off-3-mobile {
					margin-left: 25%;
				}

				.row > .col-4-mobile {
					width: 33.33333%;
				}

				.row > .off-4-mobile {
					margin-left: 33.33333%;
				}

				.row > .col-5-mobile {
					width: 41.66667%;
				}

				.row > .off-5-mobile {
					margin-left: 41.66667%;
				}

				.row > .col-6-mobile {
					width: 50%;
				}

				.row > .off-6-mobile {
					margin-left: 50%;
				}

				.row > .col-7-mobile {
					width: 58.33333%;
				}

				.row > .off-7-mobile {
					margin-left: 58.33333%;
				}

				.row > .col-8-mobile {
					width: 66.66667%;
				}

				.row > .off-8-mobile {
					margin-left: 66.66667%;
				}

				.row > .col-9-mobile {
					width: 75%;
				}

				.row > .off-9-mobile {
					margin-left: 75%;
				}

				.row > .col-10-mobile {
					width: 83.33333%;
				}

				.row > .off-10-mobile {
					margin-left: 83.33333%;
				}

				.row > .col-11-mobile {
					width: 91.66667%;
				}

				.row > .off-11-mobile {
					margin-left: 91.66667%;
				}

				.row > .col-12-mobile {
					width: 100%;
				}
				

				.row > .off-12-mobile {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0px;
					margin-left: 0px;
				}

					.row.gtr-0 > * {
						padding: 0px 0 0 0px;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0px;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0px;
						}

				.row.gtr-25 {
					margin-top: -5px;
					margin-left: -5px;
				}

					.row.gtr-25 > * {
						padding: 5px 0 0 5px;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -5px;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 5px;
						}

				.row.gtr-50 {
					margin-top: -10px;
					margin-left: -10px;
				}

					.row.gtr-50 > * {
						padding: 10px 0 0 10px;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -10px;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 10px;
						}

				.row {
					margin-top: -20px;
					margin-left: -20px;
				}

					.row > * {
						padding: 20px 0 0 0;
					}

					.row.gtr-uniform {
						margin-top: -20px;
					}

						.row.gtr-uniform > * {
							padding-top: 20px;
						}

				.row.gtr-150 {
					margin-top: -30px;
					margin-left: -30px;
				}

					.row.gtr-150 > * {
						padding: 30px 0 0 30px;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -30px;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 30px;
						}

				.row.gtr-200 {
					margin-top: -40px;
					margin-left: -40px;
				}

					.row.gtr-200 > * {
						padding: 40px 0 0 40px;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -40px;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 40px;
						}
        
      #navPanel a {
        color: #eee!important;
      }
	  .fixed-bg {
		height: 250px;
		display: none;
		}
		.text-special-center {
			padding: 0!important;
		}
		.project-bg h3 {
			font-size: 32px!important;
		}
		}

/* Sections/Article */

	section, article {
		margin-bottom: 0;
	}

		

		section.special > header, section.special > footer, article.special > header, article.special > footer {
			text-align: center;
		}

	.row > section, .row > article {
		margin-bottom: 0;
	}
	article > header {
		margin: 0;
		display: flex;
		height: 80px;
		align-items: center;
		text-align: center;
		width: 100%;
	}


	header {
		margin: 3.5em 0 1em 0;
	}

		header > p {
			display: block;
			font-size: 1.5em;
			margin: 1em 0 2em 0;
			line-height: 1.5em;
			text-align: inherit;
			font-family: 'Raleway', sans-serif;
			font-weight: 300;
		}
		header h1 {
			font-family: 'Raleway', sans-serif;
			font-weight: 700;
    		font-size: 66px!important;
		}

	footer {
		margin: 2.5em 0 0 0;
	}
	.mail-footer {
		padding: 20px 0;
		margin: 13px!important;
		color: #2f6a9c;
	}
	.mail-footer i {
		padding-right: 10px;
	}

/* Image */
	.why-cc {
		text-align: center;
		margin-bottom: 2em;
	}
	.why-cc .button {
		background: #e0e0e0;
		color: #222;
		font-size: 25px;
		width: 50%;
	}

	.full-img {
		width: 100%;
	}

	.image {
		position: relative;
		display: inline-block;
		border: 0;
		outline: 0;
	}

		.image img {
			display: block;
			width: 100%;
		}

		.image.fit {
			display: block;
			width: 100%;
		}

		.image.featured {
			display: block;
			width: 100%;
			margin: 0 0 4em 0;
		}

		.image.left {
			float: left;
			margin: 0 2em 2em 0;
		}

		.image.right {
			float: right;
			margin: 0 0 2em 2em;
			max-width: 600px;
		}

		.image.centered {
			display: block;
			margin: 0 0 2em 0;
		}

			.image.centered img {
				margin: 0 auto;
				width: auto;
			}
		
		.services-icon {
			text-align: center;
			font-size: 127px;
			padding: 40px;
			color: #000;
		}
		.image-services {
			width: 100%;
		}
		.mt0
		{
			margin-top: 0;
		}
		.mt2
		{
			margin-top: 2em;
		}
    	.mt4
		{
			margin-top: 4em;
		}
		.mt6
		{
			margin-top: 6em;
		}
		.mb2
		{
			margin-bottom: 2em;
		}
    	.mb4
		{
			margin-bottom: 4em;
		}
		.mb6
		{
			margin-bottom: 6em;
		}
		.pt0 {
			padding-top: 0!important;
		}
		.pb0 {
			padding-bottom: 0!important;
		}

/* List */

	ul {
		list-style: circle;
		padding-left: 2em;
	}

		ul li {
			padding-left: 0.5em;
		}

	ol {
		list-style: decimal;
		padding-left: 1.25em;
	}

		ol li {
			padding-left: 0.25em;
		}

/* Actions */

	ul.actions {
		margin: 3em 0 0 0;
		list-style: none;
		padding-left: 0;
	}

		form ul.actions {
			margin-top: 0;
		}

		ul.actions li {
			display: inline-block;
			margin: 0 0.75em 0 0.75em;
			padding-left: 0;
		}

			ul.actions li:first-child {
				margin-left: 0;
			}

			ul.actions li:last-child {
				margin-right: 0;
			}

/* Divided */

	ul.divided {
		list-style: none;
		padding-left: 0;
	}

		ul.divided li {
			border-top: solid 1px rgba(128, 128, 128, 0.2);
			padding-top: 1.5em;
			margin-top: 1.5em;
			padding-left: 0;
		}

			ul.divided li:first-child {
				border-top: 0;
				padding-top: 0;
				margin-top: 0;
			}
	
	ul.horizontal-graph {
		display: inline-flex;
		align-items: center;
		width: 100%;
		text-align: center;
		list-style-type: none;
		padding: 0
	}
	ul.horizontal-graph li {
		padding: 2em;
		width: 25%;
	}
	ul.horizontal-graph li i {
		display: block;
		font-size: 120px;
		padding-bottom: 20px;
	}
	.text-special-center {
		text-align: center;
		font-size: 1.5em;
		padding-bottom: 2em;
		line-height: 1.5em;
		padding: 0 3em 2em;
	}

/* Menu */

	ul.menu {
		height: 1em;
		line-height: 1em;
		list-style: none;
		padding-left: 0;
	}

		ul.menu li {
			display: inline-block;
			border-left: solid 1px rgba(128, 128, 128, 0.2);
			padding-left: 1.25em;
			margin-left: 1.25em;
		}

			ul.menu li:first-child {
				border-left: 0;
				padding-left: 0;
				margin-left: 0;
			}

/* Icons */

	ul.icons {
		position: relative;
		background: rgba(128, 128, 128, 0.05);
		border-radius: 4em;
		display: inline-block;
		padding: 0.35em 0.75em 0.35em 0.75em;
		font-size: 1.25em;
		cursor: default;
		list-style: none;
	}

		ul.icons li {
			display: inline-block;
			padding-left: 0;
		}

			ul.icons li a {
				display: inline-block;
				background: none;
				width: 2.5em;
				height: 2.5em;
				line-height: 2.5em;
				text-align: center;
				border-radius: 100%;
				border: 0;
				color: inherit;
			}

				ul.icons li a:hover {
					color: #ef8376;
				}

/* Form */

	form label {
		display: block;
		font-weight: 400;
		color: #483949;
		margin: 0 0 1em 0;
	}

	form input[type="text"],
	form input[type="email"],
	form input[type="password"],
	form select,
	form textarea {
		-webkit-appearance: none;
		display: block;
		border: 0;
		background: #fafafa;
		width: 100%;
		border-radius: 0;
		border: solid 1px #E5E5E5;
		padding: 15px 20px;
		-moz-transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out;
		-webkit-transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out;
		-ms-transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out;
		transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out;
	}

		form input[type="text"]:focus,
		form input[type="email"]:focus,
		form input[type="password"]:focus,
		form select:focus,
		form textarea:focus {
			background: #fff;
			border-color: #df7366;
		}

	form input[type="text"],
	form input[type="email"],
	form input[type="password"],
	form select {
		line-height: 1em;
	}

	form textarea {
		min-height: 9em;
	}

	form ::-webkit-input-placeholder {
		color: #555 !important;
	}

	form :-moz-placeholder {
		color: #555 !important;
	}

	form ::-moz-placeholder {
		color: #555 !important;
	}

	form :-ms-input-placeholder {
		color: #555 !important;
	}

	form ::-moz-focus-inner {
		border: 0;
	}

/* Table */

	table {
		width: 100%;
	}

		table.default {
			width: 100%;
		}

			table.default tbody tr {
				border-top: solid 1px #e5e5e5;
			}

				table.default tbody tr:first-child {
					border-top: 0;
				}

				table.default tbody tr:nth-child(2n+1) {
					background: #fafafa;
				}

			table.default td {
				padding: 0.5em 1em 0.5em 1em;
			}

			table.default th {
				text-align: left;
				font-weight: 400;
				padding: 0.5em 1em 0.5em 1em;
			}

			table.default thead {
				border-bottom: solid 2px #e5e5e5;
			}

			table.default tfoot {
				border-top: solid 2px #e5e5e5;
			}

/* Button */

	input[type="button"],
	input[type="submit"],
	input[type="reset"],
	button,
	.button {
		position: relative;
		display: inline-block;
		background: #2f6a9c;
		color: #fff;
		text-align: center;
		border-radius: 0;
		text-decoration: none;
		padding: 0.65em 3em 0.65em 3em;
		border: 0;
		cursor: pointer;
		outline: 0;
		font-weight: 300;
		-moz-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
		-webkit-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
		-ms-transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
		transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
	}

		input[type="button"]:hover,
		input[type="submit"]:hover,
		input[type="reset"]:hover,
		button:hover,
		.button:hover {
			color: #fff;
			background: #3955a2;
		}

		input[type="button"].alt,
		input[type="submit"].alt,
		input[type="reset"].alt,
		button.alt,
		.button.alt {
			background: #2B252C;
		}

			input[type="button"].alt:hover,
			input[type="submit"].alt:hover,
			input[type="reset"].alt:hover,
			button.alt:hover,
			.button.alt:hover {
				background: #3B353C;
			}

/* Post */

	.post.stub {
		text-align: center;
	}

		.post.stub header {
			margin: 0;
		}

/* Icons */

	.icon {
		text-decoration: none;
		text-decoration: none;
	}

		.icon:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
			text-transform: none !important;
			font-family: 'Font Awesome 5 Free';
			font-weight: 400;
		}

		.icon:before {
			line-height: inherit;
			font-size: 1.25em;
		}

		.icon > .label {
			display: none;
		}

		.icon.solid:before {
			font-weight: 900;
		}

		.icon.brands:before {
			font-family: 'Font Awesome 5 Brands';
		}

		.icon.circled {
			position: relative;
			display: inline-block;
			background: #2b252c;
			color: #fff;
			border-radius: 100%;
			width: 3em;
			height: 3em;
			line-height: 3em;
			text-align: center;
			font-size: 1.25em;
		}

			header .icon.circled {
				margin: 0 0 2em 0;
			}

/* Wrapper */

	.wrapper {
		background: #fff;
		margin: 0 0 0 0;
		padding: 2em 0 6em 0;
	}

		.wrapper.style2 {
			padding-top: 0;
		}

/* Header */

	#header {
		position: relative;
		background-image: url("assets/images/header.jpg");
		background-size: cover;
		background-position: center center;
		background-attachment: fixed;
		color: #fff;
		text-align: center;
		padding: 7.5em 0 2em 0;
		cursor: default;
	}

		#header:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 100%;
		}

		#header .inner {
			position: relative;
			z-index: 1;
			margin: 0;
			display: inline-block;
			vertical-align: middle;
		}

		#header header {
			display: inline-block;
		}

			#header header > p {
				font-size: 1.5em;
				margin: 10px 0 60px;
			}

    .title-services {
      text-align: center;
      font-weight: 100;
      font-size: 40px;
      padding: 0 15% 80px;
      line-height: 1.2;
      color: #2f6a9c;
    } 
		#header h1 {
			color: #fff;
			font-size: 3em;
			line-height: 1em;
		}

			#header h1 a {
				color: inherit;
			}

		#header .button {
			display: inline-block;
			border-radius: 8px;
			width: auto;
			text-align: center;
			font-size: 1.25em;
			padding: 15px;
		}

		#header hr {
			top: 1.5em;
			margin-bottom: 3em;
			border-bottom-color: rgba(192, 192, 192, 0.35);
			box-shadow: inset 0 1px 0 0 rgba(192, 192, 192, 0.35);
		}

			#header hr:before, #header hr:after {
				background: rgba(192, 192, 192, 0.35);
			}

		#header footer {
			margin: 1em 0 0 0;
		}

	.homepage #header {
		height: 100vh;
		min-height: 40em;
	}

		.homepage #header h1 {
			font-size: 4em;
		}

		.homepage #header:after {
			content: '';
			background: #1C0920;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			-moz-transition: opacity 2.5s ease-in-out;
			-webkit-transition: opacity 2.5s ease-in-out;
			-ms-transition: opacity 2.5s ease-in-out;
			transition: opacity 2.5s ease-in-out;
			-moz-transition-delay: 0.5s;
			-webkit-transition-delay: 0.5s;
			-ms-transition-delay: 0.5s;
			transition-delay: 0.5s;
		}

	.is-preload #header:after {
		opacity: 1;
	}

/* Nav */

	#nav {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		text-align: center;
		padding: 1.5em 0 1.5em 0;
		z-index: 1;
	}

		#nav > ul {
			line-height: 0px;
			position: relative;
			display: inline-flex;
			margin: 0;
			height: 75px;
			align-items: center;
			/**border-left: solid 1px rgba(192, 192, 192, 0.35);
			border-right: solid 1px rgba(192, 192, 192, 0.35);**/
		}

			#nav > ul:before, #nav > ul:after {
				content: '';
				display: block;
				width: 250px;
				position: absolute;
				top: 50%;
				margin-top: -2px;
				height: 6px;
				border-top: solid 1px rgba(192, 192, 192, 0.35);
				border-bottom: solid 1px rgba(192, 192, 192, 0.35);
			}

			#nav > ul:before {
				left: 100%;
				margin-left: 1px;
			}

			#nav > ul:after {
				right: 100%;
				margin-right: 1px;
			}
			#nav .logo {
				margin: 0;
    			padding: 9px 15px;
			}

			#nav .logo img {
				height: 85px;
			}
			

			#nav > ul > li {
				display: inline-block;
				margin: 0 0.5em 0 0.5em;
				border-radius: 0.5em;
				padding: 0.85em;
				border: solid 1px transparent;
				-moz-transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
				-webkit-transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
				-ms-transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
				transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;
			}

				#nav > ul > li.active {
					border-color: rgba(192, 192, 192, 0.35);
				}

				#nav > ul > li > a, #nav > ul > li > span, #nav > ul > li > .dropdown > .dropdown-toggle > a {
					display: block;
					color: inherit;
					text-decoration: none;
					border: 0;
					outline: 0;
					font-size: 20px;
    				font-weight: 300;
					line-height: 1.3;
					color: #fff!important;
				}
        #nav > ul > li > .dropdown > .dropdown-toggle > a {
          display: inline-block;
        }

				#nav > ul > li > ul {
					display: none;
				}
				#dropdownMenuButton {
					font-size: 20px;
    				font-weight: 300;
				}
				.dropdown-menu {
					position: absolute;
					z-index: 1000;
					display: none;
					min-width: 10rem;
					padding: 10px 0 15px 0!important;
					margin: 0;
					font-size: 1rem;
					color: #ffffff!important;
					text-align: left;
					list-style: none;
					background-color: #2b252c!important;
					border: none!important;
					border-radius: 0!important;
				}
				.dropdown-menu.show {
					transform: translate3d(0, 42px, 0)!important;
					will-change: initial!important;
				}
				.dropdown-item a {
					color: #ffffff!important;
					font-weight: 300!important;
					padding: 15px!important;
					display: block;
          text-decoration: none;
				}
				.dropdown-item a:focus, .dropdown-item a:hover {
					background-color: transparent!important;
    				color: #2f6a9c!important;
				}
				#nav > ul > li > a:hover {
					color: #2f6a9c!important;
				}

			.logo-mobile {
				display: none;
			}

	.dropotron {
		background: rgba(255, 255, 255, 0.975);
		padding: 1em 1.25em 1em 1.25em;
		line-height: 1em;
		height: auto;
		text-align: left;
		border-radius: 0.5em;
		box-shadow: 0 0.15em 0.25em 0 rgba(0, 0, 0, 0.25);
		min-width: 12em;
		margin-top: -1em;
		list-style: none;
	}

		.dropotron li {
			border-top: solid 1px rgba(128, 128, 128, 0.2);
			color: #5b5b5b;
			padding-left: 0;
		}

			.dropotron li:first-child {
				border-top: 0;
			}

			.dropotron li:hover {
				color: #ef8376;
			}

			.dropotron li a, .dropotron li span {
				display: block;
				border: 0;
				padding: 0.5em 0 0.5em 0;
				-moz-transition: color 0.35s ease-in-out;
				-webkit-transition: color 0.35s ease-in-out;
				-ms-transition: color 0.35s ease-in-out;
				transition: color 0.35s ease-in-out;
			}

		.dropotron.level-0 {
			margin-top: 2em;
			font-size: 0.9em;
		}

			.dropotron.level-0:before {
				content: '';
				position: absolute;
				left: 50%;
				top: -0.7em;
				margin-left: -0.75em;
				border-bottom: solid 0.75em rgba(255, 255, 255, 0.975);
				border-left: solid 0.75em rgba(64, 64, 64, 0);
				border-right: solid 0.75em rgba(64, 64, 64, 0);
			}
	
	.project-bg {
		background: url('assets/images/about2.jpg');
		background-size: cover;
		padding: 3em 0 4em 0;

	}
	.project-bg-2 {
		background: url('assets/images/project2.jpg');
		background-size: cover;
		padding: 3em 0 4em 0;

	}
	.project-bg-2 p {
		padding: 70px 5%!important;
		color: #fff;
		font-size: 36px;
		text-align: center;
		line-height: 1.4;
		font-weight: 100;
	}

	.project-bg ul {
		text-align: center;
		list-style: none;
		font-size: 36px;
		line-height: 1.7;
		font-weight: 300;
		color: #fff;
	}
	.project-bg h3 {
		font-size: 42px;
    	font-weight: 100;
	}

/* Banner */

	#banner, #go {
		background: #fff;
		text-align: center;
		padding: 4.5em 0 4.5em 0;
		margin-bottom: 0;
	}

		#banner header > p, #go header > p {
			margin-bottom: 25px;
			padding: 0;
    		font-size: 24px;
		}

/* Content */

	#content > hr {
		top: 3em;
		margin-bottom: 6em;
	}

	#content > section {
		margin-bottom: 0;
	}

/* Sidebar */

	#sidebar > hr {
		top: 3em;
		margin-bottom: 6em;
	}

		#sidebar > hr.first {
			display: none;
		}

	#sidebar > section {
		margin-bottom: 0;
	}

/* Main */

	#main {
		margin-bottom: 0;
	}

		#main section:first-of-type {
			padding-top: 2em;
		}

/* Footer */

	#contact {
		position: relative;
		overflow: hidden;
		padding: 6em 0 2em 0;
		background: #2b252c;
		color: #fff;
	}
	

		#contact .icon.circled {
			background: #fff;
			color: #2b252c;
		}

		#contact header {
			text-align: center;
			cursor: default;
		}

		#contact h2, #contact h3, #contact h4, #contact h5, #contact h6 {
			color: #fff;
		}

		#contact .contact {
			text-align: center;
		}

			#contact .contact p {
				text-align: center;
				margin: 0 0 3em 0;
			}
		
		#contact .contact-form {
				padding: 0 20%;
			}

		#contact .copyright {
			text-align: center;
			color: rgba(128, 128, 128, 0.75);
			font-size: 0.8em;
			cursor: default;
		}

			#contact .copyright a {
				color: rgba(128, 128, 128, 0.75);
			}

				#contact .copyright a:hover {
					color: rgba(212, 212, 212, 0.85);
				}

		.do-list {
			line-height: 1.3;
			font-size: 22px!important;
			font-weight: 300;
			color: #636363;
		}
		.do-list > span:nth-child(even) {
			background-color: #ddd;
		}
		.do-list > span > strong {
			font-weight: 500;
			color: #484848;
			display: block;
			line-height: 1.4;
			padding-top: 10px;
		}
		.do-list > span {
			padding: 40px;
			display: block;
			width: 100%;
			border-bottom: 1px solid #ffffff;
		}
		.do-text {
			padding: 0 17%!important;
		}
		.do-text span {
			padding: 10px 0 18px;
			display: block;
			font-weight: 400;
		}
		.bg-grey {
			background-color: #e8e8e8!important;
			position: relative;

		}
		#banner.bg-grey {
			padding: 0;
		}
		#banner.bg-grey > header {
			margin:  0;
		}
		#banner.bg-grey > header .do-list {
			margin:  0;
		}

		
		.personnel-lists  ul li {
			font-size: 20px;
			font-weight: 300;
			color: #292929;
		}
		.personnel-lists  h3 {
			padding: 0 0 8px 10px;
			color: #2f6a9c;
		}
		
		/* .bg-grey:after {
			content: "\f201";
			font-family: FontAwesome;
			left: 0;
			position: absolute;
			top: 0;
			font-size: 30em;
			margin: 0 auto;
			color: #dadada;
			z-index: 0;
			width: 100%;
		} */
		.bg-grey header {
			position: relative;
			z-index: 4;
		}


/* Carousel */
	#dropdownMenuButton	{
		background-color: transparent;
    	border: none;
	}

	.carousel {
		position: relative;
		overflow: hidden;
		padding: 2em 0 2em 0;
		margin-bottom: 0;
	}

		.carousel .forward, .carousel .backward {
			position: absolute;
			top: 50%;
			width: 6em;
			height: 12em;
			margin-top: -6em;
			cursor: pointer;
		}

			.carousel .forward:before, .carousel .backward:before {
				content: '';
				display: block;
				width: 6em;
				height: 6em;
				border-radius: 100%;
				background-color: rgba(72, 57, 73, 0.5);
				position: absolute;
				top: 50%;
				margin-top: -3em;
				-moz-transition: background-color 0.35s ease-in-out;
				-webkit-transition: background-color 0.35s ease-in-out;
				-o-transition: background-color 0.35s ease-in-out;
				-ms-transition: background-color 0.35s ease-in-out;
				transition: background-color 0.35s ease-in-out;
				
			}

			.carousel .forward:after, .carousel .backward:after {
				content: '';
				width: 3em;
				height: 3em;
				position: absolute;
				top: 50%;
				margin: -1.5em 0 0 0;
				background: url("assets/images/arrow.svg") no-repeat center center;
			}

			.carousel .forward:hover:before, .carousel .backward:hover:before {
				background-color: rgba(239, 131, 118, 0.75);
			}

		.carousel .forward {
			right: 0;
		}

			.carousel .forward:before {
				right: -3em;
			}

			.carousel .forward:after {
				right: -0.25em;
			}

		.carousel .backward {
			left: 0;
		}

			.carousel .backward:before {
				left: -3em;
			}

			.carousel .backward:after {
				left: -0.25em;
				-moz-transform: scaleX(-1);
				-webkit-transform: scaleX(-1);
				-ms-transform: scaleX(-1);
				transform: scaleX(-1);
			}

		.carousel .reel {
			white-space: nowrap;
			position: relative;
			padding: 0 2em 0 2em;
			vertical-align: top!important;
			display: grid;
    		grid-template-columns: repeat(3, 32%);
			grid-row-gap: 25px;
			grid-column-gap: 2%;
		}

		.carousel article {
			display: inline-block;
			width: 100%;
			background: #fff;
			text-align: center;
			padding: 0 1em 1.6em 1em;
			margin: 0;
			white-space: normal;
			opacity: 1.0;
			transition: opacity 0.75s ease-in-out;
			vertical-align: top!important;
			position: relative;
			padding-bottom: 150px;
			border: 1px solid #eaeaea;
		}

			.carousel article.loading {
				opacity: 0;
			}

			.carousel article .image {
				position: relative;
				left: -1em;
				top: 0;
				width: auto;
				margin-right: -2em;
				margin-bottom: 0;
			}

			.carousel article p {
				text-align: center;
				font-weight: 300;
				font-size: 18px;
				line-height: 1.5;
				width: 100%;
				background-color: #fdfdfd;
				position: absolute;
				left: 0;
				bottom: 20px;
				height: 130px;
				display: flex;
				align-items: flex-end;
				margin: 0 auto;
				border-top: 1px solid #eaeaea;
			}
			.carousel article p span {
				display: block;
				width: 100%;
				font-size: 18px;
				font-weight: 400;
				padding: 0 25px;
				line-height: 1.3
			}
			.carousel article p span strong a {
				display: block;
				padding: 10px;
				margin-top: 10px;
				background-color: #2b252c;
				color: #fff!important;
				text-decoration: none!important;
				font-size: 16px;
				font-weight: 300;
			}
			.carousel article p span strong a:hover {
				background-color: #3955a2;
			}
			.carousel article h3 {
				font-size: 21px!important;
				line-height: 1.2;
				width: 100%;
				margin: 0;
				font-weight: 400;
			}

/* Wide */

	@media screen and (max-width: 1680px) {

		/* Basic */

			body, input, textarea, select {
				font-size: 14pt;
				line-height: 1.75em;
			}

		/* Carousel */

			.carousel {
				padding: 1.5em 0 1.5em 0;
			}

				.carousel .reel {
					padding: 0 1.5em 0 1.5em;
				}

				.carousel article {
					width: 100%;
					margin: 0;
				}

	}

/* Normal */

	@media screen and (max-width: 1280px) {

		/* Basic */

			body, input, textarea, select {
				font-size: 12pt;
				line-height: 1.5em;
			}

		/* Wrapper */

			.wrapper {
				padding-left: 2em;
				padding-right: 2em;
			}

		/* Header */

			#header {
				background-attachment: scroll;
			}

				#header .inner {
					padding-left: 2em;
					padding-right: 2em;
				}
			
			#nav > ul:before, #nav > ul:after {
				display: none;
			}

		/* Banner */

			#banner {
				padding-left: 2em;
				padding-right: 2em;
			}

		/* Footer */

			#contact {
				padding-left: 2em;
				padding-right: 2em;
			}

	}

/* Narrow */

/* Narrower */
	@media (min-width: 768px) {
		.container, .container-md, .container-sm {
    		/* max-width: 100%!important; */
		}
	}

	@media screen and (max-width: 840px) {

		/* Basic */

			body, input, textarea, select {
				font-size: 13pt;
				line-height: 1.65em;
			}

			.tweet {
				text-align: center;
			}

			.timestamp {
				display: block;
				text-align: center;
			}

		/* Footer */

			#contact {
				padding: 4em 2em 4em 2em;
			}

		/* Carousel */

			.carousel {
				padding: 1.25em 0 1.25em 0;
			}

				.carousel article {
					width: 100%;
					margin: 0;
				}
			.carousel .reel {
				grid-template-columns: repeat(2, 49%);
				grid-row-gap: 20px;
			}
	}

/* Mobile */

	#navPanel, #titleBar {
		display: none;
	}

	@media screen and (max-width: 736px) {

		/* Basic */

			html, body {
				overflow-x: hidden;
			}
			.col-12-small {
				width: 100%!important;
			}

			body, input, textarea, select {
				font-size: 14pt;
				line-height: 1.5em;
				font-weight: 300;
			}

			h2 {
				font-size: 1.75em;
			}

			h3 {
				font-size: 1.25em;
			}

			hr {
				top: 3em;
				margin-bottom: 6em;
			}
			.carousel .reel {
				grid-template-columns: auto;
				grid-gap: 20px;
			}

		/* Section/Article */

			section, article {
				margin-bottom: 0em;
			}

				section:last-child, article:last-child {
					margin-bottom: 0;
				}

			.row > section, .row > article {
				margin-bottom: 0em;
			}

			header > p {
				font-size: 1.25em;
			}

		/* Image */

			.image.featured {
				margin: 0 0 2em 0;
			}

			.image.left {
				margin: 0 1em 1em 0;
			}

			.image.centered {
				margin: 0 0 1em 0;
			}

		/* Actions */

			form ul.actions {
				margin-top: 0;
			}

			ul.actions li {
				display: block;
				margin: 0.75em 0 0 0;
			}

				ul.actions li:first-child {
					margin-top: 0;
				}

		/* Menu */

			ul.menu {
				height: auto;
				text-align: center;
			}

				ul.menu li {
					display: block;
					border: 0;
					padding: 0.75em 0 0 0;
					margin: 0;
				}

					ul.menu li:first-child {
						padding-top: 0;
					}

		/* Icons */

			ul.icons {
				font-size: 1em;
				padding: 0.35em 0.5em 0.35em 0.5em;
			}

				ul.icons li a {
					width: 2.25em;
				}

		/* Button */

			input[type="button"],
			input[type="submit"],
			input[type="reset"],
			button,
			.button {
				display: block;
				padding: 0;
				line-height: 3em;
				width: 100%;
			}

		/* Nav */

			#page-wrapper {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: transform 0.5s ease;
				padding-bottom: 1px;
			}

      .title-services {
        text-align: center;
        font-weight: 100;
        font-size: 26px;
        padding: 0 0 20px;
        line-height: 1.2;
        color: #2f6a9c;
      } 

			#navButton {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transition: -moz-transform 0.5s ease;
				-webkit-transition: -webkit-transform 0.5s ease;
				-ms-transition: -ms-transform 0.5s ease;
				transition: all 0.5s ease;
				display: block;
				height: 44px;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 10003;
			}

				#navButton .toggle {
					text-decoration: none;
					position: absolute;
					right: 0;
					top: 0;
					width: 20%;
					height: 100%;
					border: 0;
					outline: 0;
				}

					#navButton .toggle:before {
						-moz-osx-font-smoothing: grayscale;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						text-transform: none !important;
						font-family: 'Font Awesome 5 Free';
						font-weight: 900;
					}

					#navButton .toggle:before {
						text-decoration: none;
						font-style: normal;
						content: '\f0c9';
						color: #fff;
						color: rgb(226 226 226 / 75%);
						text-align: center;
						display: block;
						font-size: 30px;
						width: 45px;
						height: 30px;
						line-height: 30px;
						position: absolute;
						right: 13px;
						margin-left: 0;
						border-top: 0;
						border-radius: 0 0 0.35em 0.35em;
						top: 18px;
					}

			#navPanel {
				-moz-backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				-ms-backface-visibility: hidden;
				backface-visibility: hidden;
				-moz-transform: translateY(0);
				-webkit-transform: translateY(0);
				-ms-transform: translateY(0);
				transform: translateY(0);
				-moz-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
				-ms-transition: all 0.5s ease;
				transition: all 0.5s ease;
				display: none;
				height: 100%;
				left: 0;
				overflow-y: auto;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 10002;
				background: #1f1920; 
				box-shadow: inset 0px -2px 5px 0px rgba(0, 0, 0, 0.25);
				font-size: 1em;
				padding: 45px 15px 15px;

			}

				#navPanel:before {
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background-size: 128px 128px;
				}

				#navPanel .link {
					position: relative;
					z-index: 1;
					display: block;
					text-decoration: none;
					padding: 10px 5px;
					color: #ddd;
					border: 0;
					border-top: dotted 1px rgba(255, 255, 255, 0.05);
					font-size: 16px;

				}

					#navPanel .link:first-child {
						border-top: 0;
					}

					#navPanel .link.depth-0 {
						color: #fff;
						font-weight: 600;
					}

				#navPanel .indent-1 {
					display: inline-block;
					width: 1em;
				}

				#navPanel .indent-2 {
					display: inline-block;
					width: 2em;
				}

				#navPanel .indent-3 {
					display: inline-block;
					width: 3em;
				}

				#navPanel .indent-4 {
					display: inline-block;
					width: 4em;
				}

				#navPanel .indent-5 {
					display: inline-block;
					width: 5em;
				}

				#navPanel .depth-0 {
					color: #fff;
				}

			/*body.navPanel-visible #page-wrapper {
				-moz-transform: translateY(50vh);
				-webkit-transform: translateY(50vh);
				-ms-transform: translateY(50vh);
				transform: translateY(50vh);
			}

			body.navPanel-visible #navButton {
				-moz-transform: translateY(50vh);
				-webkit-transform: translateY(50vh);
				-ms-transform: translateY(50vh);
				transform: translateY(50vh);
			}

			body.navPanel-visible #navPanel {
				-moz-transform: translateY(0);
				-webkit-transform: translateY(0);
				-ms-transform: translateY(0);
				transform: translateY(0);
			}*/
			body.navPanel-visible #navPanel {
				display: block;
				
			}
			body.navPanel-visible #page-wrapper { 
				display: none;
			}

		/* Wrapper */

			.wrapper {
				padding: 0em 1em 3em 1em;
			}

		/* Header */

			#header {
				background-attachment: scroll;
				padding: 2.5em 0 0 0;
			}

				#header .inner {
					padding-top: 1.5em;
					padding-left: 1em;
					padding-right: 1em;
				}

				#header header > p {
					font-size: 1em;
				}

				#header h1 {
					font-size: 1.75em!important;
				}

				#header hr {
					top: 1em;
					margin-bottom: 2.5em;
				}

			.homepage #header {
				min-height: 0;
				padding: 5.5em 0 5em 0;
				height: auto;
			}

				.homepage #header:before {
					display: none;
				}

				.homepage #header h1 {
					font-size: 2.5em!important;
				}

		/* Nav */

			#nav {
				display: none;
			}

		/* Banner */

			#banner {
				padding: 3em 2em 3em 2em;
			}
			#banner.bg-grey header {
				margin: 0;
			}
			#banner.bg-grey {
				padding: 0;
			}

		/* Sidebar */

			#sidebar > hr:first-of-type {
				display: block;
			}

		/* Main */

			#main > header {
				text-align: center;
			}

		/* Footer */

			#contact {
				padding-left: 1em;
				padding-right: 1em;
			}

		/* Carousel */

			.carousel {
				padding: 1.5em 0 0.5em 0;
			}

				.carousel .reel {
					padding: 0 0.5em 0 0.5em;
				}

				.carousel article {
					width: 100%;
					padding-bottom: 150px;
					margin: 0;
				}

					.carousel article .image {
						margin-bottom: 0;
					}
					article > header {
						margin-top: 0;
					}
					.carousel article h3 {
						height: auto;
					}
					.carousel article p {
						align-items: center;
						height: 140px;
					}

		.project-bg ul { 
			font-size: 20px;
    		padding: 0!important;
		}
		ul.horizontal-graph {
			display: block;
		}
		ul.horizontal-graph li {
			width: 100%;
		}
		.image.right, .image.image.left {
			margin: 0 auto;	
			float: none;
			display: block;
			max-width: 100%;
		}
		#contact .contact-form {
			padding: 0;
		}
		.do-list {
			font-size: 18px!important;
			margin: 0;
			
		}
		.do-list > span {
			padding: 25px;
		}
		.do-text {
			padding: 0 25px!important;
		}
		
		#banner, #go {
			padding: 3em 0;
		}
		header {
			margin: 1em 0 1em 0;
		}
		
		.logo-mobile {
			border: none;
			padding: 8px 0 0 8px;
			display: inline-block;
		}
		.logo-mobile img {
			height: 50px;
		}

	}
